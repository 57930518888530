<script setup lang="ts">
import Modal from "@/components/helpers/Modal.vue";
import Spinner from "@/components/ui/decoration/Spinner.vue";
import {ref, watch} from "vue";
import {getTranslation} from "@/ts/utilities.ts";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import RenderAction from "@/components/helpers/RenderAction.vue";
import NotificationElement from "@/components/ui/NotificationElement.vue";

interface Props {
	show: boolean,
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

let query = ref('')
let showModal = ref(false)
let isLoading = ref(false)
let voucherInput = ref('')
let voucherStatus = ref('')

function close() {
	emit('close')
}

async function checkVoucher() {
	isLoading.value = true

	const formData = new FormData()
	formData.append('EcomOrderVoucherCode', query.value)

	await fetch('/mobler-api/v2/ecom/cart/validatevoucher', {
		method: 'POST',
		body: formData
	})

	.then(response => {
		if (!response.ok) {
			throw new Error('Network response was not ok')
		}
		return response.json()
	})
	.then(data => {
		voucherStatus.value = data.status

		if (data.status === 'Success') {
			setTimeout(() => {
				window.location.reload()
			}, 1000)
		}
	})
	.catch(error => {
		console.error('There has been a problem with your fetch operation:', error)
	})
	isLoading.value = false
}

watch(() => props.show, (value) => {
	showModal.value = value;
})

watch(showModal, (value) => {
	if (value) {
		voucherInput.value.focus()
	}
})
</script>

<template>
	<Modal :show="showModal" @close="close()">
		<template v-slot:modal-title>
			{{ getTranslation('CheckoutVoucher.Title') }}
		</template>
		<template v-slot:content>
			<Spinner v-if="isLoading"
				 class="w-full">
				{{ getTranslation('CheckoutVoucher.Checking') }}
			</Spinner>

			<div v-else-if="voucherStatus === 'Success'"
				 class="flex flex-col items-center w-full">
				<div class="flex flex-col gap-y-4">
					<font-awesome-icon :icon="['fal', 'check-circle']" class="text-primary text-[4rem]"/>
					<strong>{{ getTranslation('CheckoutVoucher.Success') }}</strong>
				</div>
			</div>

			<div v-else
				class="flex flex-col items-center gap-y-4 w-full">
				<form
					  class="flex flex-col gap-y-4 w-1/2"
					  @submit.prevent="checkVoucher"
				>
					<label
						for="voucherInput">
						{{ getTranslation('Checkout.VoucherLabel') }}
					</label>
					<input
						type="text"
						class="w-full h-12 px-4 rounded-full border focus:border-black focus:outline-none"
						ref="voucherInput"
						v-model="query"
						:placeholder="getTranslation('Checkout.VoucherPlaceholder')"
					>
					<RenderAction
						action="button"
						theme="primary"
						size="lg"
						@click="checkVoucher">
						{{ getTranslation('RedeemVoucher') }}
						<font-awesome-icon :icon="['fas', 'tag']"/>
					</RenderAction>
				</form>
				<template v-if="voucherStatus === 'Error'">
					<NotificationElement
						theme="danger">
						<template #content>
							<span>{{ getTranslation('CheckoutVoucher.Error') }}</span>
						</template>
					</NotificationElement>
				</template>
			</div>
		</template>
	</Modal>
</template>
