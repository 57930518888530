<script setup lang="ts">
import {ref, watch} from 'vue'
import {debounce} from 'vue-debounce'
import {getTranslation, getSettings} from "@/ts/utilities.ts";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SearchModal from '@/components/SearchModal.vue'
import ProductCard from '@/components/ProductCard.vue'
import {ProductListViewData} from '@/interfaces/ProductListViewInterface.ts'

const settings = getSettings()

const props = defineProps({
	noResults: {
		type: Number,
	}
})

let showModal = ref(false)
let loading = ref(false)
let searchInputRef = ref('')
let query = ref('')
const products: ProductListViewData = ref([])
const productsError = ref()
let popularGroups = ref([])
let popularTerms = ref([])
let relatedProductGroups= ref([])
let noResultsText = ref('')

const productOptions = JSON.stringify({
	'options': {
		'media': true,
		'dimensions': true,
		'delivery': true,
		'cylindo': true,
		'priceAndCampaign': true,
		'variantOptionsSimple': true,
		'productDetailsSimple': true
	}
})

const search = async () => {
	loading.value = true

	await fetch(`/mobler-api/v2/products/productsearchfeed?q=${query.value}&take=9`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: productOptions
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			console.log('data', data.length)
			if (data.totalProducts > 0) {
				products.value = data.products
			} else {
				noResultsText.value = props.noResults
			}
			loading.value = false
		})
		.catch(error => {
			productsError.value = error
			console.error(error)
		})
}
function goToSearchPage() {
	if (query.value.length > 2) {
		window.location.href = `${settings?.searchResultPage}?q=${query.value}&take=22`
	}
}

// Calculate the top positioning of the modal,the modal should always be positioned so the search input is 20px inside from the top
function calculateModalPosition() {
	let searchInput = document.getElementById('js-search-bar')
	let modal = document.getElementById('js-search-modal')
	let searchInputPosition = searchInput?.getBoundingClientRect()
	let modalTop = 0
	let searchTop

	// Only if viewport width is above 768px
	if (window.innerWidth > 768) {
		modalTop = (searchInputPosition?.top ?? 0) - 20
	} else {
		// Move search input to the top of the viewport
		searchTop = (searchInputPosition?.top ?? 0) * -1 + 20
		searchInput?.classList.add('absolute')
	}

	// When search is closed, remove the absolute positioning
	if (!showModal.value) {
		searchInput?.classList.remove('absolute')
	}

	if (modal) {
		modal.style.top = `${modalTop}px`
	}
	if (searchInput) {
		searchInput.style.top = `${searchTop}px`
	}
}

async function getPopularGroups() {
	await fetch('/mobler-api/session/Relewise/PopularProductGroups')
		.then(response => response.json())
		.then(data => {
			popularGroups.value = data
		})
}

async function getPopularTerms() {
	await fetch(`/mobler-api/session/Relewise/PopularSearchTerms?pageId=7177&isLocalshop=${settings.isLocalshop}`)
		.then(response => response.json())
		.then(data => {
			popularTerms.value = data
		})
}

async function getRelatedProductGroups(term: string, take: number = 10, skip: number = 0) {
	await fetch(`/mobler-api/session/Relewise/ProductCategorySearch?term=${term}&take=${take}&skip=${skip}`)
		.then(response => response.json())
		.then(data => {
			relatedProductGroups.value = data
		})
}

watch(query, debounce((value: string) => {
	// Whilst query is above 2 characters, clear and search, if under clear
	if (value.length > 2) {
		products.value = []
		search()
		getRelatedProductGroups(value)
	} else {
		products.value = []
		relatedProductGroups.value = []
		noResultsText.value = ''
	}
}, 225))

// If modal is opened, append the overflow-hidden class to the html element
watch(showModal, (value) => {
	//calculateModalPosition()

	// The first time the modal is opened, get the popular groups and terms
	if (value && popularGroups.value.length === 0) {
		getPopularGroups()
		getPopularTerms()
	}

	if (value) {
		document.documentElement.classList.add('overflow-hidden', 'modal-open')
	} else {
		document.documentElement.classList.remove('overflow-hidden', 'modal-open')
		searchInputRef.value.blur()
	}
})

</script>

<template>
	<div class="flex items-center">


	<form
		class="relative rounded-full border border-grey flex w-full"
		:class="showModal ? 'z-50 transform w-[70vw] lg:w-full -translate-y-16 lg:translate-y-0' : ''"
		@submit.prevent="goToSearchPage"
	>
		<font-awesome-icon :icon="['fal', 'magnifying-glass']" class="flex items-center pl-4 absolute top-4" />
		<input
			type="text"
			class="w-full h-12 pl-10 rounded-full border-transparent focus:ring-0 focus:border-none focus:outline-none"
			ref="searchInputRef"
			@focus="showModal = true"
			:placeholder="getTranslation('Search.Placeholder')"
			v-model="query"
		>
		<button type="submit" class="rounded-full bg-grey text-black font-bold border-2 border-transparent hover:text-black hover:border-grey-dark active:border-grey-dark active:bg-grey py-2 px-4">
			{{ getTranslation('Search') }}
		</button>
	</form>

		<!-- TODO: RenderAction -->
	<button
		type="button"
		class="items-center ml-auto px-3 text-base hover:underline"
		:class="showModal ? 'flex lg:hidden z-50 transform -translate-y-16 lg:translate-y-0' : 'hidden'"
		@click="showModal = false"
	>
		<span class="mr-2">{{ getTranslation('Close') }}</span>
		<font-awesome-icon :icon="['fal', 'close']" />
	</button>
	</div>


	<SearchModal
		:show="showModal"
		:loading="loading"
		@close="showModal = false">
		<template #modal-title>
			<!-- TODO: SetShop based text -->
		</template>
		<template #search>
		</template>
		<template #popular>
			<template v-if="relatedProductGroups.length > 0">
				<span class="flex font-bold mb-2">
					{{ getTranslation('Search.Categories') }}
				</span>
				<ul class="p-0 list-none border-t">
					<li v-for="group in relatedProductGroups" :key="group.key">
						<a :href="group.Link" class="flex py-2 border-b cursor-pointer text-black hover:border-black">{{ group.Name }}</a>
					</li>
				</ul>
			</template>
			<template v-else-if="popularTerms.length > 0">
				<span class="flex font-bold mb-2">
					{{ getTranslation('Search.PopularSearchTerms') }}
				</span>
				<ul class="p-0 list-none border-t">
					<li v-for="term in popularTerms" :key="term.key">
						<a :href="term.Link" class="flex py-2 border-b cursor-pointer text-black hover:border-black">{{ term.Term }}</a>
					</li>
				</ul>
			</template>
		</template>
		<template #content>
			<div v-if="products.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
				<ProductCard v-for="product in products" :product="product" :key="product.key" />
			</div>
			<div v-else-if="noResults" v-html="noResults">
			</div>
			<div v-else class="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-6">
				<div v-for="group in popularGroups" class="group" :key="group.key">
					<a :href="group.Link">
						<div class="bg-beige-light rounded-md overflow-hidden mb-2">
							<img :src="group.Image" class="object-cover object-center w-full aspect-[16/10] transition-opacity group-hover:opacity-85" :alt="group.Title" width="320" height="200" />
						</div>
						<p class="text-black group-hover:underline"><strong>{{ group.Name }}</strong></p>
					</a>
				</div>
			</div>
			<div v-if="products.length > 8" class="flex align-center justify-center">
				<a :href="`${settings?.searchResultPage}?q=${query}&autoload=true`" class="mt-8 btn brand-black btn-big transition py-2 bg-black text-white hover:no-underline">
					{{ getTranslation('Search.ShowAllResults') }}
				</a>
			</div>
		</template>
	</SearchModal>
</template>
