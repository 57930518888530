
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

// scss modules
import './scss/main.scss';

// web/vue components
import { createApp } from "vue";
import ClickOutside from './directives/ClickOutsideDirective';

// Automatically grab and register components
const modules = import.meta.glob([
	'./components/**/*.vue',
	'./views/**/*.vue',
	], { eager: true })

// settings should be globally available
let settings = {};

async function getSettings() {
	const settingsEndPoint = '/mobler-api/v2/ecom/settings/website'
	const response = await fetch(settingsEndPoint);
	let data;
	try {
		data = await response.json();
	}
	catch (e) {
		console.error(e);
		data = {};
	}
	if (Object.keys(data).length > 0) {
		settings = {...data};
	}

	return settings;
}

let translations = [];
async function getTranslations() {
	const culture = document.body.dataset.culture;
	const design = document.body.dataset.design;
	const translationsEndPoint = `${location.hostname === 'localhost' ? 'http://localhost:53150' : 'https://mobler.dk' }/dwapi/translations/${design}/${culture}`
	const response = await fetch(translationsEndPoint);
	let data;
	try {
		data = await response.json();
	}
	catch (e) {
		console.error(e);
		data = [];
	}
	if (data.length > 0) {
		translations = [...data];
	}
}

getTranslations()
	.then(() => getSettings())
	.then(() => {
		Object.entries(modules).forEach(([path, module]) => {
			// @ts-ignore
			const name = path.split('/').pop().split('.').shift().toLowerCase()

			// For each Vue component found, register it
			if (document.querySelector( name) === null) return

			var els = document.querySelectorAll( name);
			els.forEach((v,i,p) => {
				const props = datasetToObject(v);
				createApp(module.default,props)
					.provide('translations', translations)
					.provide('settings', settings)
					.directive('click-outside', ClickOutside)
					.mount(v);
			})
		})
})


// Parse the incoming data attributes, to correctly handle JSON as well as numbers and strings
function datasetToObject(element: Element) {
    const dataset = element.dataset;
    const obj = {};

    for (const key in dataset) {
        if (dataset.hasOwnProperty(key)) {
            const value = dataset[key];

            try {
                // Try parsing as JSON
                obj[key] = JSON.parse(value);
            } catch (error) {
                // Fallback to string or number
                obj[key] = isNaN(value) ? value : Number(value);
            }
        }
    }

    return obj;
}


// ts modules
import "./ts/Models";
import "./ts/CustomBindings";
import "./ts/ViewModels";
import "./ts/fontawesome-icons";



