<script setup lang="ts">
import {watch, ref, computed, onBeforeMount, onMounted} from 'vue'
import {getTranslation} from '@/ts/utilities.ts'
import Cylindo from '@/ts/CustomBindings/Cylindo.ts'
import Modal from '@/components/helpers/Modal.vue'
import {VariantModalData} from '@/interfaces/ProductViewInterface'
import RenderAction from '@/components/helpers/RenderAction.vue'
import SelectElement from '@/components/ui/form/SelectElement.vue'
import PriceElement from '@/components/PriceElement.vue'
interface Props {
	productData: object,
	variantGroupData: object,
	colorGroupIds: object,
	show: boolean
}

const props = defineProps<Props>()

const variantModal: VariantModalData = {
	productName: props.productData?.name,
	productPrice: props.productData?.productPrice,
	media: props.productData?.media,
	cylindo: props.productData?.cylindo,
	selectedVariantId: props.productData?.variantId
}

const emit = defineEmits(['variantChange', 'close'])

let selectedVariant = ref({})
const productPrice = ref(null)
const imageContainer = ref(null)
let appliedFilters = ref({
	color: [],
	material: []
})

const productOptions = JSON.stringify({
	'options': {
		'priceAndCampaign': true,
		'productDetailsAdvanced': false,
		'media': true,
		'dimensions': false,
		'delivery': false,
		'cylindo': false,
		'specifications': false,
		'variantOptionsAdvanced': false,
		'moreOptions': false
	}
})

const getMaterial = (materialType) => {
	const splitMaterial = materialType.split('_')
	return splitMaterial.length > 1 ? splitMaterial[1] : ''
}
const hasColorOptions = () => {
	return props.variantGroupData.variantGroupDetails.colorFilterValues.length > 0
}
const getColorOptions = () => {
	const colorFilters = Object.values(
		props.variantGroupData.variantGroupDetails.colorFilterValues.reduce((acc, p) => {
			const key = p.value ? p.value : ''
			if (!acc[key]) {
				acc[key] = p
			}
			return acc
		}, {})
	)
	return  colorFilters.map(item => {
		return {
			id: item.value,
			name: item.label,
			value: item.value,
			thumbColor: item.attribute
		}
	})
}

const hasMaterialOptions = () => {
	return props.variantGroupData.variantGroupDetails.materialFilterValues.length > 0
}

const getMaterialOptions = () => {
	const materialFilters = Object.values(
		props.variantGroupData.variantGroupDetails.materialFilterValues.reduce((acc, p) => {
			const key = p.value && p.value.split('_').length > 1 ? p.value.split('_')[1] : ''
			if (!acc[key]) {
				acc[key] = p
			}
			return acc
		}, {})
	)


	return  materialFilters.map(item => {
		return {
			id: item.value,
			name: item.label,
			value: item.value,
			thumbColor: item.attribute
		}
	})
}

const modalSelectedCylindoFeatures = ref({})
const setVariant = async (variantOption: object) => {
	selectedVariant.value = variantOption
	const cylindoFeature = variantOption?.designProperties?.cylindoFeatures
	if (Cylindo.shouldLoad() && cylindoFeature) {
		const splitClindoFeature = cylindoFeature.split(' ')
		Object.assign(modalSelectedCylindoFeatures.value, Cylindo.selectedCylindoFeatures, {[splitClindoFeature[0].toUpperCase()]: splitClindoFeature[1]})
		//Object.assign(Cylindo.selectedCylindoFeatures, {[splitClindoFeature[0].toUpperCase()]: splitClindoFeature[1]})
		Cylindo.setFeatures('modal', modalSelectedCylindoFeatures.value)
	}
	const productRequest = await fetch(`/mobler-api/v2/products/productsFeed?productIds=${props.productData.id}_${selectedVariant?.value?.designProperties?.variantId }`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: productOptions
	})
	productPrice.value = null
	const productResponds = await productRequest.json()
	productPrice.value = productResponds?.products[0]?.priceAndCampaign
	imageContainer.value.scrollIntoView()

	variantModal.media = productResponds?.products[0]?.media
}
const resetFilters = (types) => {
	types.forEach(t => appliedFilters.value[t] = [])
}

const updateAppliedFilters = (type, options) => {
	if (options.length) {
		resetFilters([type])
		options.forEach((option) => {
			if (type === 'material') {
				const optionDetails = props.variantGroupData.variantGroupDetails.variantOptionDetails.find(o => o.materialType === option.value)
				const value = getMaterial(optionDetails.materialType)
				if (!appliedFilters.value.material.includes(value)) {
					appliedFilters.value.material.push(value)
				} else {
					appliedFilters.value.material = appliedFilters.value.material.filter(e => e !== value)
				}
			} else if (type === 'color') {
				const optionDetails = props.variantGroupData.variantGroupDetails.variantOptionDetails.find(o => o.color === option.value)
				const value = optionDetails.color
				if (!appliedFilters.value.color.includes(value)) {
					appliedFilters.value.color.push(value)
				} else {
					appliedFilters.value.color = appliedFilters.value.color.filter(e => e !== value)
				}
			}
		})
	} else {
		resetFilters([type])
	}
}

let filteredList = computed(() => {
	if(!props.variantGroupData){
		return
	}
	const hasFilters = (appliedFilters.value.color.length + appliedFilters.value.material.length) > 0
	let result = props.variantGroupData.variantGroupDetails.variantOptionDetails
	if (hasFilters) {
		result = props.variantGroupData.variantGroupDetails.variantOptionDetails.filter((vg) => {
			const material = getMaterial(vg.materialType)
			const color = vg.color
			let filteredList = []
			if(appliedFilters.value.material.length && appliedFilters.value.color.length ){
				filteredList = appliedFilters.value.material.includes(material) && appliedFilters.value.color.includes(color)
			}
			else if (appliedFilters.value.material.length) {
				filteredList = appliedFilters.value.material.includes(material)
			}
			else if (appliedFilters.value.color.length) {
				filteredList = appliedFilters.value.color.includes(color)
			}
			return filteredList
		})
	}
	return result

})

const setActiveVariantOnLoad = () =>{
	productPrice.value = props.productData.priceAndCampaign
	const variantGroupIndex = props.productData.variantOptionsAdvanced.variantGroups?.findIndex(vg => props.colorGroupIds.includes(vg.id) === true)
	const colorVariantId = props.productData.variantId.split('.')[variantGroupIndex]
	setVariant(props.variantGroupData.variantGroupDetails.variantOptionDetails?.find(vo => vo.id === colorVariantId) ?? props.variantGroupData.variantGroupDetails.variantOptionDetails[0])
}


onMounted(async () => {
	if (!props.variantGroupData) return
	setActiveVariantOnLoad()
})

watch([() => props.show, () => appliedFilters], ([newIsOpen, newAppliedFilters], [oldIsOpen, oldAppliedFilters]) => {
	if (newIsOpen) {
		setActiveVariantOnLoad()
	}

	if (newAppliedFilters !== oldAppliedFilters) {
		filteredList.value
	}
}, { deep: true })

watch(() => appliedFilters, () => {
	filteredList.value
}, {deep: true})
</script>

<template>
	<Modal class="variant-modal"
		   :show="props.show"
		   theme="variant"
		   @close="emit('close')"
	>
		<template v-slot:modal-title>
			{{ getTranslation('Cylindo - Modal - Intro - Text') }}
		</template>
		<template v-slot:content>
			<div class="flex flex-wrap w-full divide-x divide-grey">
				<div class="flex flex-col h-auto md:h-full w-full md:w-1/2" ref="imageContainer">
					<div class="p-4 lg:px-8 min-h-[7rem] border-grey border-b">
						<h4>{{ variantModal.productName }}</h4>
						<PriceElement direction="horizontal" theme="page" size="sm" :price-data="productPrice" v-if="productPrice" />
					</div>
					<div class="flex justify-center h-auto" v-if="variantModal?.cylindo?.isUsingCylindoAssets"
						 v-html="Cylindo.renderCylindoElement(variantModal.cylindo, 'cylindo-360', {id: 'cylindo-modal-container', controls: 'zoom'})">
					</div>
					<img v-else
						 width="800"
						 height="500"
						 class="product-image main-image modal-main-image"
						 :src="variantModal?.media?.images?.[0]?.url"
						 :alt="variantModal.productName"/>
				</div>

				<div class="flex flex-col w-full md:w-1/2">
					<div class="flex flex-col md:flex-row p-4 lg:px-8 gap-4 border-grey border-b">
						<div class="block md:flex"
							 v-if="hasColorOptions()">
							<SelectElement
								filterType="color"
								:multiple="true"
								type="dropdown"
								:options="getColorOptions()"
								:label="getTranslation('Cylindo - Modal - Colors - Header')"
								@updateFilter="updateAppliedFilters"
							>
								{{ getTranslation('Cylindo - Modal - All - Text') }}
							</SelectElement>
						</div>
						<div class="block md:flex"
							 v-if="hasMaterialOptions()">
							<SelectElement
								filterType="material"
								:multiple="true"
								type="dropdown"
								:options="getMaterialOptions()"
								:label="getTranslation('Cylindo - Modal - Material - Header')"
								@updateFilter="updateAppliedFilters">
								{{ getTranslation('Cylindo - Modal - All - Text') }}
							</SelectElement>
						</div>
					</div>

					<div class="min-h-full max-h-[calc(90vh-250px)] bg-white overflow-y-scroll">
						<div class="mobler-custom-select-container mobler-custom-select-variant p-4 lg:px-8">
							<label v-for="variantOption in filteredList">
								<input class="variant-color"
									   name="ModelVariantOption"
									   :title="`Cylindo - Modal - Option - ${variantOption.name} - Label`"
									   type="radio"
									   :value="variantOption.id"
									   :checked="selectedVariant.id === variantOption.id"
									   @change="setVariant(variantOption)">
								<span v-if="variantOption?.designProperties?.hasVisualContent"
									  class="border select-option js-mobler-modal-variant-option-background"
									  :style="variantOption.designProperties.backgroundFabric">
									<span class="image-magnifier-large js-mobler-modal-variant-option-zoom"
										  :style="variantOption.designProperties.backgroundZoom"></span>
								</span>
								<span class="custom-control-description text-center block small text-muted"
									  :class="!variantOption?.designProperties?.hasVisualContent ? 'text-only-modal-variant-option' : ''">
									{{ variantOption.name }}
								</span>
							</label>
						</div>
					</div>
					<div class="sticky bottom-0 bg-primary-light">
						<div class="w-full">
							<div class="flex justify-between items-center gap-2 p-4 lg:px-8">
								<RenderAction
									action="button"
									theme="link"
									@click="emit('close')"
								>
									{{ getTranslation('Cylindo - Modal - Cancel - Text') }}
								</RenderAction>
								<RenderAction
									action="button"
									theme="primary"
									@click="emit('variantChange', selectedVariant?.designProperties.variantId || selectedVariant?.variantProduct.variantId, selectedVariant)"
								>
									{{ getTranslation('Cylindo - Modal - Save - Text') }}
								</RenderAction>
							</div>
						</div>
					</div>
				</div>

			</div>
		</template>
	</Modal>
</template>
