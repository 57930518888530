import * as ko from 'knockout';
import $ from 'jquery';
import 'select2';

export default class Select2SelectShop {
    public static ToggleSelect2DropDown(element: any) {
        $(element).select2('open');
    }

    public init(element: any, valueAccessor: () => any, allBindingsAccessor?: KnockoutAllBindingsAccessor, viewModel?: any, bindingContext?: KnockoutBindingContext) {
        var value = valueAccessor();
        var overlay = value.overlay;
        var placeholder = value.placeholder;
        var noResult = value.noResult;
        var hideSelector = value.hideSelector;
        function formatState(state: any) {
            if (!state.id) {
                return state.text;
            }
            var baseUrl = "/user/pages/images/flags";
            var shopnameText = state.text.replace("{{", "<span class='hidden'>").replace("}}", "</span>");
            var $state = $(
				// '<div class="fs0 text-primary">' + shopnameText + '</div>' + '<div class="text-black fs0">' + state.title + '</div>'
				'<div class="py-2 px-3 flex items-center justify-content-between">'
				+ '<div>'
				+ '<div class="text-primary font-weight-semibold fs-12px">' + shopnameText + '</div>'
				+ '<div class="fs-12px text-black">'
				+ state.title
				+ '</div>'
				+ '</div>'
				+ '<div class="color-grey">'
				+ '<i class="fas fa-angle-right fs2"></i>'
				+ '</div>'
				+ '</div>'
            );
            return $state;
        };

        function matchCustom(params: any, data: any) {
            if ($.trim(params.term) === '') {
                return data;
            }

            if (typeof data.text === 'undefined') {
                return null;
            }

            var splitSearch = params.term.toLowerCase().split(' ');
            var match = true;

            for (var i = 0; i < splitSearch.length; i++) {
                if (data.text.toLowerCase().indexOf(splitSearch[i]) <= -1) {
                    match = false;
                }
            }

            if (match) {
                return data;
            }
            else {
                return null;
            }
		}

        $(element).select2({
			templateResult: formatState,
            matcher: matchCustom,
            language: {
                noResults: function () {
                    return noResult;
                  },
            }
		});

        if(hideSelector) {
            $(element).siblings('.select2').find('.selection').addClass("hidden");
        }

		// keep this hidden at page reload;
		$('.select2-append-html').hide(1);

        $(element).on('select2:open', function (e) {
			$(".select2-search__field").attr('placeholder', placeholder);
			$('.select2-append-html').show(1);
		});

		$(element).on('select2:close', function (e) {
			$('.select2-append-html').hide(1);
        });

        $('<div class="' + overlay + '"></div>').insertAfter($(element).siblings(".select2"));
        $(element).on("change", function (e) {
            var shopName = $(element).val();
            var date = new Date();
            date.setTime(date.getTime() + (100000000000));
            var expires = "; expires=" + date;
            document.cookie = "ShopName" + "=" + shopName + expires + "; path=/";

            //SAVE ORDER PROGRESS BEFORE RELOADING PAGE
            if ($("#ordersubmit").length > 0) {
                var customerName = $("#EcomOrderCustomerName").val().toString();
                localStorage.setItem('customerName', customerName);
                var customerAddress = $("#EcomOrderCustomerAddress").val().toString();
                localStorage.setItem('customerAddress', customerAddress);
                var customerZip = $("#EcomOrderCustomerZip").val().toString();
                localStorage.setItem('customerZip', customerZip);
                var customerCity = $("#EcomOrderCustomerCity").val().toString();
                localStorage.setItem('customerCity', customerCity);
                var customerPhone = $("#EcomOrderCustomerPhone").val().toString();
                localStorage.setItem('customerPhone', customerPhone);
                var customerEmail = $("#EcomOrderCustomerEmail").val().toString();
                localStorage.setItem('customerEmail', customerEmail);
                var customerPayMethod = $("input[name=EcomCartPaymethodID]:checked").val();
                if (customerPayMethod) {
                    localStorage.setItem('customerPayMethod', customerPayMethod.toString());
                }
                var customerDeliveryMethod = $("input[name=EcomCartShippingmethodID]:checked").val();
                if (customerDeliveryMethod) {
                    localStorage.setItem('customerDeliveryMethod', customerDeliveryMethod.toString());
                }
            }
            if (window.location.href.indexOf("om-os") > -1) {
                var shopNameForLink = "/" + shopName.toString().toLowerCase().replace('+', '-').replace(/%c3%b8/g, 'oe').replace(/%c3%a6/g, 'ae').replace(/%c3%a5/g, 'aa') + "/om-os";
                window.location.replace(shopNameForLink);
            }
            else {
                location.reload();
            }
        });
    }
}

ko.bindingHandlers['select2SelectShop'] = new Select2SelectShop();
