<script setup lang="ts">
const props = defineProps({
	data: {
		type: Object,
	},
	theme: {
		type: String,
		validator(value, props) {
			return ['primary', 'secondary', 'outline', 'faded', 'link'].includes(<string>value)
		}
	},
	size: {
		type: String,
		validator(value, props) {
			return ['sm', 'normal', 'lg'].includes(<string>value)
		}
	},
	disabled: {
		type: Boolean,
		default: false
	}
})
</script>

<template>
	<button
		type="button"
		class="rounded-full hover:no-underline focus:no-underline"
		:disabled="props.disabled"
		>
		<slot></slot>
	</button>
</template>
